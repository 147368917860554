import React from "react";

import style from "./index.module.css";

const Index = () => {
  return (
    <div className={style.footer}>
      Projet de fin d'année Master 2 Informatique - Réalisé avec ♥
    </div>
  );
};

export default Index;
