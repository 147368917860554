const trefles = {
    img1 : require('./1.png'),
    img2 : require('./2.png'),
    img3 : require('./3.png'),
    img4 : require('./4.png'),
    img5 : require('./5.png'),
    img6 : require('./6.png'),
    img7 : require('./7.png'),
    img8 : require('./8.png'),
    img9 : require('./9.png'),
    img10 : require('./10.png'),
    img11 : require('./11.png'),
    img12 : require('./12.png'),
    img13 : require('./13.png'),
  }
  
  export default trefles;
  